<script lang="ts" setup> 
    
    const props = defineProps({ 
        blok: Object 
    })

    const richtext = computed(() => renderRichText(props.blok.richtext))  

    const width = computed(() => {
        return props.blok?.image?.filename.split('/')[5].split('x')[0]
    })

    const height = computed(() => {
        return props.blok?.image?.filename.split('/')[5].split('x')[1]
    })

</script>

<template>
   <div v-editable="blok" class="grid grid-col-1 lg:grid-cols-2 gap-6 lg:gap-8 min-h-500 md:min-h-600 lg:min-h-700 xl:min-h-800 items-center" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-4 justify-center items-center lg:justify-start lg:items-start" :class="{'order-1 lg:order-2': blok?.reverse_columns}">
            <div v-if="blok?.heading || blok?.subheading || blok?.richtext" class="flex flex-col gap-4 justify-center items-center lg:justify-start lg:items-start text-center lg:text-left max-w-[900px]">
                <div v-if="blok?.heading || blok?.subheading" class="flex flex-col gap-2 justify-center items-center lg:justify-start lg:items-start">
                        <component
                            v-if="blok && blok?.heading" 
                            :is="`headings-${blok?.heading_tag || 'h1'}`" 
                            :value="blok?.heading"
                            :classes="blok?.heading_style || 'h2'" 
                        />
                        <component
                            v-if="blok && blok?.subheading" 
                            :is="`headings-${blok.subheading_tag || 'h2'}`" 
                            :value="blok?.subheading"
                            :classes="blok?.subheading_style || 'h3'"
                        />
                </div>
                <div v-if="blok?.richtext" v-html="richtext" class="richtext | flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500 [&>ul]:flex [&>ul]:flex-col [&>ul]:gap-1 [&>ul]:items-start [&>ul]:md:items-start"/>
            </div>
            <div v-if="blok?.buttons && blok?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center items-start lg:justify-start lg:items-start">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>
            <ClientOnly v-if="!blok?.hideReviews">
                <iframe v-if="blok?.theme === 'light'"
                    :id="`iframe--${blok?._uid}`"
                    src="/reviews--badge--light.html"
                    class="mx-auto lg:mr-auto lg:ml-0 h-[62px]"
                    title="Reviews"
                />
                <iframe v-else
                    :id="`iframe--${blok?._uid}`"
                    src="/reviews--badge--dark.html"
                    class="mx-auto lg:mr-auto lg:ml-0 h-[62px]"
                    title="Reviews"
                />
            </ClientOnly>
        </div>
        <div v-if="blok?.image" class="flex flex-col gap-4">
            <NuxtImg
                v-if="blok?.image && blok?.image?.filename"
                provider="storyblok"
                :width="width"
                :height="height" 
                :sizes="`xs:100vw sm:${width} md:${width} narrow:${width} lg:${width} xl:${width} 1xl:${width} normal:${width} 2xl:${width} 3xl:${width} wide:${width}`"
                quality="75"
                fit="in"
                :src="blok?.image?.filename"
                :alt="blok?.image?.alt || blok?.image?.title || '#'" 
                fetchpriority="high"
                decoding="sync"
                loading="eager"
                preload
                class="w-full h-auto mx-auto rounded-1"
            />
        </div>
   </div>
</template>

<style lang="scss">
    .richtext {
        ol li, ul li {
            @apply relative m-0 p-0 px-4
        }

        ol li:before, ul li:before {
            content: '';
            position: absolute;
            top:4px;
            left:0;
            width:20px;
            height:20px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' fill='rgb(114, 191, 0)' width='24'%3E%3Cpath d='M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z'/%3E%3C/svg%3E");
            background-size: contain;
        }
    }
</style>